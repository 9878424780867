import React from 'react';
import './styles/Pages.css';
import smart from '../assets/projects/cover/SMART.jpg'
import monitor from '../assets/projects/cover/MONITOR.jpg'
import icma from '../assets/projects/cover/ICMA_EXTRANET.jpg'
import gravi from '../assets/projects/cover/GRAVI.jpg';
import tekonecta from '../assets/projects/cover/TEKONECTA.jpg';
import desport from '../assets/projects/cover/DESPORT.jpg';
import ProjectCard from './ProjectCard';
import ktagile from '../assets/projects/cover/KTAGILE.jpg';

const projectsData = [
   {
      id: 1,
      title: 'SMART',
      subtitle: 'Plataforma de verificación documentaria',
      description: 'Sistema utilizado para la gestión de documentos, desde 2 perspectivas, la del contratista y la del cliente. El primero carga la documentación de su personal, mientras el segundo valida, aprueba y autoriza el acceso.',
      extended: 'SMART es un proyecto que surgió de la necesidad del cliente, para incluir dentro del servicio de vigilancia, una forma de corroborar y verificar ciertos documentos necesarios que el personal que ingresa a sus instalaciones debe de cumplir por normativa. \n\nEste sistema maneja una vista para el contratista, quien es el que va a realizar alguna labor o visita en las instalaciones del cliente, y que por lo tanto debe de cargar en la plataforma todos los documentos de la empresa y del personal. Y una vista para el administrador (cliente) quien verifica la documentación, la apruebe, y autoriza al personal de la solicitud de acceso (unidade de medida de SMART).',
      image: smart,
      tools: ['PHP', 'HTML5', 'CSS3', 'JavaScript', 'jQuery'],
      company: 'SOLMAR SECURITY SAC',
      difficulty: 5,
      file: 'smart',
      carousel: ['login.jpg', 'AD_home.jpg', 'AD_solicitudes.jpg'],
   },
   {
      id: 2,
      title: 'MONITOR',
      subtitle: 'Plataforma de gestión de auditorías',
      description: 'Sistema desarrollado para gestionar las auditorías realizadas a las embarcaciones. En esta, se cargan tanto las auditorías como la documentación presentada antes las autoridades.',
      image: monitor,
      tools: ['PHP', 'HTML5', 'CSS3', 'JavaScript'],
      company: 'SOLMAR CONSULTING SAC',
      difficulty: 5,
      file: 'monitor',
      carousel: ['login.jpg', 'reportes.jpg', 'auditorias.jpg'],
   },
   {
      id: 3,
      title: 'ICMA EXTRANET',
      subtitle: 'Plataforma de inscripciones a cursos virtuales',
      description: 'Sistema desarrollado para la inscripción a los cursos de inducción de empresas a nivel nacional pertenecientes a diversos rubros como pesqueras, servicio logístico para la minería o servicios aeroportuarios.',
      image: icma,
      tools: ['PHP', 'HTML5', 'CSS3', 'JavaScript'],
      company: 'SOLMAR TRAINING SAC',
      difficulty: 4,
      file: 'icma',
      carousel: ['login.jpg', 'inscripciones.jpg', 'detalles.jpg'],
   },
   {
      id: 4,
      title: 'INVENTARIO GRAVI',
      subtitle: 'Plataforma de gestión de inventario',
      description: 'Sistema desarrollado para gestionar los productos de inventario de una empresa de lubricantes, dedicada tanto a la repación y mantenimiento de vehículos, como a la comercialización de productos afines.',
      image: gravi,
      tools: ['PHP', 'HTML5', 'CSS3', 'JavaScript'],
      company: 'INVERSIONES GRAVI SAC',
      difficulty: 4,
      file: 'gravi',
      carousel: ['login.jpg', 'home.jpg', 'proforma.jpg', 'inventario.jpg'],
   },
   {
      id: 5,
      title: 'TEKONECTA',
      subtitle: 'Plataforma de creación y diseño de tarjetas de presentación ',
      description: 'Aplicación web para generar una tarjeta de presentación que agrupa diversos enlaces de sitios digitales en una única página.',
      image: tekonecta,
      tools: ['PHP', 'HTML5', 'CSS3', 'JavaScript'],
      company: 'TEKONECTA SAC',
      difficulty: 4,
      file: 'tekonecta',
      carousel: ['login.jpg', 'paso1.jpg', 'paso2.jpg'],
   },
   /*{
      title: 'CASA DE CAMBIO CELSO',
      subtitle: 'Plataforma de gestión de transacciones',
      description: 'Sistema desarrollado para gestionar las transacciones o cambios realizados en una casa de cambio de soles, dólares, euros y pesos chilenos.',
      image: monitor,
      tools: ['HTML5', 'CSS3', 'JavaScript'],
      company: 'XYZ',
      difficulty: 3,
      carousel: ['login.jpg', 'AD_home.jpg', 'AD_solicitudes.jpg'],
   },*/
   {
      id: 6,
      title: 'DESPORT',
      subtitle: 'Sistema Integral para Portafolios de Diseño ',
      description: 'Sistema desarrollado para la creación y gestión de un portafolio personalizado enfocado en los diseñadores o afines, mediante el cual se muestran sus trabajos o proyectos digitales.',
      extended: " ",
      image: desport,
      tools: ['ANGULAR', 'HTML5', 'CSS3', 'JavaScript'],
      company: 'PROPIO',
      difficulty: 3,
      file: 'desport',
      carousel: ['login.jpg', 'home.jpg', 'proyectos.jpg', 'portafolio.jpg'],
   },
   /*{
      title: 'GENERADOR DE TARJETAS',
      subtitle: 'Plataforma de Gestión de Auditorías',
      description: 'Sistema desarrollado para gestionar la auditorías realizadas a las embarcaciones. En esta, se cargan tanto las auditorías como la documentación.',
      image: monitor,
      tools: ['HTML5', 'CSS3', 'JavaScript'],
      company: 'XYZ',
      difficulty: 4,
      carousel: ['login.jpg', 'AD_home.jpg', 'AD_solicitudes.jpg'],
   },*/
   {
      id: 7,
      title: 'TABLERO KANBAN',
      subtitle: 'Plataforma de Gestión de Proyectos',
      description: 'Sistema web desarrollado para gestionar proyectos y flujos de trabajo, así como la supervisión de los mismos.',
      image: ktagile,
      tools: ['REACT', 'HTML5', 'CSS3', 'JavaScript'],
      company: 'PROPIO',
      difficulty: 4,
      file: 'ktagile',
      carousel: ['home.jpg', 'spaces.jpg', 'tablero2.jpg'],
   },
];

const Projects = () => {

   return (
      <div className='projects'>
         <div className='text-projects'>
            <h1 className='title_projects'>Mis proyectos</h1>
            <p className='subtitle_projects'>Lista de proyectos en lo que he colaborado o he desarrollado desde cero, durante mi trayectoria laboral</p>
         </div>
         <div className="wrap">
            {projectsData.map((project, index) => (
               <ProjectCard
                  key={index}
                  project={project}
                  delay={index * 200} // Retraso de 200ms entre cada card
               />
            ))}
         </div>
      </div>
   );
};

export default Projects;