import React from 'react';

const Contact = () => {
    return (
        <div className='contact'>
            <h1>Contáctame</h1>
            <p>Información</p>
            <div className="contact-form">
                <div id="form-messages"></div>
                <form id="ajax-contact" method="post" role="form" action="https://noecer.com/contact.php" data-toggle="validator">
                    <div className="form-group has-feedback wow fadeInLeft" data-wow-delay="0.6s">
                        <input type="text" className="form-control" id="name" name="name" placeholder="Nombres y Apellidos" autoComplete='off' required />
                        <span className="glyphicon form-control-feedback" aria-hidden="true"></span>
                        <div className="help-block with-errors"></div>
                    </div>

                    <div className="form-group has-feedback wow fadeInRight" data-wow-delay="0.8s">
                        <input type="email" className="form-control" id="email" name="email" placeholder="Correo Electrónico" autoComplete='off' required />
                        <span className="glyphicon form-control-feedback" aria-hidden="true"></span>
                        <div className="help-block with-errors"></div>
                    </div>

                    <div className="form-group has-feedback wow fadeInLeft" data-wow-delay="1s">
                        <textarea data-minlength="10" className="form-control" rows={8} id="message" name="message" placeholder="Mensaje" autoComplete='off' required></textarea>
                        <span className="glyphicon form-control-feedback" aria-hidden="true"></span>
                        <div className="help-block with-errors"></div>
                    </div>

                    <div className="wow fadeInUp" data-wow-delay="1s">
                        <button type="submit" id="submit" name="submit" className="btn btn-lg">ENVIAR</button>
                    </div>
                </form>

            </div>

            <div className='social_media'>  
                <a href="https://www.linkedin.com/in/noemicervera/" target='_blank' type='button' title='Linkedin'><i className="fa fa-linkedin-square"></i></a>
                <a href="https://github.com/noemi98" target='_blank' type='button' title='GitHub'><i className="fa fa-github"></i></a>
            </div>
        </div>
    );
};

export default Contact;