import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer>
            <p>©2024 Mi portafolio NOECER. Todos los derechos reservados.</p>
        </footer>
    );
};

export default Footer;