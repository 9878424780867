import React, { useState } from 'react';
/*import './ProjectCard.css';*/ // Asegúrate de tener el CSS en un archivo separado
import { useInView } from 'react-intersection-observer';

const ProjectCard = ({ project, delay}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  //Para el anterior y siguiente de la imagen del modal
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? project.carousel.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === project.carousel.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Configuración para la animación al entrar en vista
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className="wrap1" ref={ref}>
      <div
        className={`tile ${inView ? 'animate-tile' : ''}`}
        onClick={openModal}
        style={{ transitionDelay: `${delay}ms` }}
      >
        <div className="tile-header"><h1>{project.title}</h1></div>
        <img src={project.image} alt={project.title} className="tile-image" />
        <div className="text">
          <h2 className="animate-text">{project.subtitle}</h2>
          <p className="animate-text">{project.description}</p>
        </div>
      </div>

      {isModalOpen && (
        <div className={`modal ${isModalOpen ? 'show' : ''}`} onClick={closeModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <div className='modal-header'>
              <h1>{project.title}</h1>
              <span className="close" onClick={closeModal}>&times;</span>
            </div>
            <div className='modal-body'>
              <div className="modal-left">
                {/* Carrusel de imágenes */}
                <button className="prev-button" onClick={handlePrevClick}>
                  &#10094;
                </button>
                <img
                  src={`${process.env.PUBLIC_URL}/${project.file}/${project.carousel[currentImageIndex]}`}
                  alt={`Imagen ${currentImageIndex + 1}`}
                  className="carousel-image"
                />
                <button className="next-button" onClick={handleNextClick}>
                  &#10095;
                </button>
              </div>
              <div className="modal-right" style={{ whiteSpace: 'pre-line' }}>

                <p>{project.description}</p>
                <div className="tools">
                  {project.tools.map(tool => (
                    <p className="tool" key={tool}>{tool}</p>
                  ))}
                </div>
                <div className="company">Empresa: {project.company}</div>
                <div className="difficulty">Complejidad: &nbsp;
                  {[...Array(5)].map((_, index) => (
                    <span key={index} className="star">
                      {index < project.difficulty ? '★' : '☆'}
                    </span>
                  ))}
                </div>
              </div>
            </div>


          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectCard;
