import React, { useRef, useEffect } from 'react';
import './styles/Pages.css';
import html5 from '../assets/skills/html5_fn.png';
import css3 from '../assets/skills/css_fn.png'
import js from '../assets/skills/js_fn.png'
import php from '../assets/skills/php_fn.png'
import python from '../assets/skills/py_fn.png'
import java from '../assets/skills/java_fn.png'
import psd from '../assets/skills/psd_fn.png'
import ai from '../assets/skills/ai_fn.png'
import ux from '../assets/skills/ux-ui-fn.png'
import sql_server from '../assets/skills/sql_server.png'

const About = () => {
   const skillsRef = useRef(null);
   //console.log("se añade la animacion 11");
   useEffect(() => {
      const observer = new IntersectionObserver(
         (entries) => {
            entries.forEach((entry) => {
               //console.log('Entrando al observer', entry.isIntersecting); // Verifica si el entry se está detectando

               if (entry.isIntersecting) {
                  //console.log("Se añade la animación");
                  document.querySelectorAll('.skill-html').forEach((element) => {
                     element.style.animation = 'progress-html 2s ease-in-out';
                  });
                  document.querySelectorAll('.skill-css').forEach((element) => {
                     element.style.animation = 'progress-css 2s ease-in-out';
                  });
                  document.querySelectorAll('.skill-js').forEach((element) => {
                     element.style.animation = 'progress-js 2s ease-in-out';
                  });
                  document.querySelectorAll('.skill-php').forEach((element) => {
                     element.style.animation = 'progress-php 2s ease-in-out';
                  });
                  document.querySelectorAll('.skill-py').forEach((element) => {
                     element.style.animation = 'progress-py 2s ease-in-out';
                  });
                  document.querySelectorAll('.skill-java').forEach((element) => {
                     element.style.animation = 'progress-java 2s ease-in-out';
                  });
                  document.querySelectorAll('.skill-psd').forEach((element) => {
                     element.style.animation = 'progress-psd 2s ease-in-out';
                  });
                  document.querySelectorAll('.skill-ai').forEach((element) => {
                     element.style.animation = 'progress-ai 2s ease-in-out';
                  });
                  document.querySelectorAll('.skill-ux').forEach((element) => {
                     element.style.animation = 'progress-ux 2s ease-in-out';
                  });
                  document.querySelectorAll('.skill-sqlserver').forEach((element) => {
                     element.style.animation = 'progress-sqlserver 2s ease-in-out';
                  });
               } else {
                  //console.log("Se remueve la animación");
                  document.querySelectorAll('.skill-html').forEach((element) => {
                     element.style.animation = 'none';
                  });
                  document.querySelectorAll('.skill-css').forEach((element) => {
                     element.style.animation = 'none';
                  });
                  document.querySelectorAll('.skill-js').forEach((element) => {
                     element.style.animation = 'none';
                  });
                  document.querySelectorAll('.skill-php').forEach((element) => {
                     element.style.animation = 'none';
                  });
                  document.querySelectorAll('.skill-py').forEach((element) => {
                     element.style.animation = 'none';
                  });
                  document.querySelectorAll('.skill-java').forEach((element) => {
                     element.style.animation = 'none';
                  });
                  document.querySelectorAll('.skill-psd').forEach((element) => {
                     element.style.animation = 'none';
                  });
                  document.querySelectorAll('.skill-ai').forEach((element) => {
                     element.style.animation = 'none';
                  });
                  document.querySelectorAll('.skill-ux').forEach((element) => {
                     element.style.animation = 'none';
                  });
                  document.querySelectorAll('.skill-sqlserver').forEach((element) => {
                     element.style.animation = 'none';
                  });
               }
            });
         },
         {
            threshold: 0.5, // Reducir el threshold para asegurar que la animación se dispare
         }
      );

      if (skillsRef.current) {
         //console.log('skillsRef.current existe', skillsRef.current);
         observer.observe(skillsRef.current);
      } else {
         //console.log('skillsRef.current es null');
      }

      return () => {
         if (skillsRef.current) {
            observer.unobserve(skillsRef.current);
         }
      };
   }, []);

   return (
      <div className='about_skill'>
         <section className='about'>
            <h1>Sobre mi</h1>
            <p>Soy Bachiller en Ingeniería de Sistemas e Informática con más de 3 años de experiencia
               en el desarrollo y mantenimiento de aplicaciones robustas y escalables.
               Poseo excelentes habilidades y conocimientos en bases de datos, así como en
               tecnologías web y de escritorio. Tengo la capacidad de expresarme de manera clara,
               fluida y persuasiva. Me destaco por mi responsabilidad, liderazgo, y mi habilidad
               para trabajar bajo presión y en equipo. </p>
         </section>
         <section className="skills" id="skills" ref={skillsRef}>
            <div className='text_skills'>
               <h1 className='title_skill'>Mis Habilidades</h1>
               <p>Los proyectos que he iniciado desde cero me han permitido ganar experiencia en el uso de las siguientes tecnologías...</p>
            </div>
            <div className='skills-group'>
               <div className='skills-1'>
                  <div className="skill-data">
                     <img src={html5} alt="Logo" className='skill-logo' />
                     <div className="skill-info">
                        <div className="skill-titles">
                           <h1 className="skill-name">HTML5</h1>
                           <span className="skill-number">85%</span>
                        </div>
                        <div className="skill-bar">
                           <span className="skill-percentage skill-html"></span>
                        </div>
                     </div>
                  </div>

                  <div className="skill-data">
                     <img src={css3} alt="Logo" className='skill-logo' />
                     <div className="skill-info">
                        <div className="skill-titles">
                           <h1 className="skill-name">CSS3</h1>
                           <span className="skill-number">80%</span>
                        </div>
                        <div className="skill-bar">
                           <span className="skill-percentage skill-css"></span>
                        </div>
                     </div>
                  </div>

                  <div className="skill-data">
                     <img src={js} alt="Logo" className='skill-logo' />
                     <div className="skill-info">
                        <div className="skill-titles">
                           <h1 className="skill-name">JavaScript</h1>
                           <span className="skill-number">90%</span>
                        </div>
                        <div className="skill-bar">
                           <span className="skill-percentage skill-js"></span>
                        </div>
                     </div>
                  </div>

                  <div className="skill-data">
                     <img src={php} alt="Logo" className='skill-logo' />
                     <div className="skill-info">
                        <div className="skill-titles">
                           <h1 className="skill-name">PHP</h1>
                           <span className="skill-number">90%</span>
                        </div>
                        <div className="skill-bar">
                           <span className="skill-percentage skill-php"></span>
                        </div>
                     </div>

                  </div>

                  <div className="skill-data">
                     <img src={python} alt="Logo" className='skill-logo' />
                     <div className="skill-info">
                        <div className="skill-titles">
                           <h1 className="skill-name">Python</h1>
                           <span className="skill-number">40%</span>
                        </div>
                        <div className="skill-bar">
                           <span className="skill-percentage skill-py"></span>
                        </div>
                     </div>

                  </div>
               </div>
               <div className='skills-2'>
                  <div className="skill-data">
                     <img src={java} alt="Logo" className='skill-logo' />
                     <div className="skill-info">
                        <div className="skill-titles">
                           <h1 className="skill-name">Java</h1>
                           <span className="skill-number">45%</span>
                        </div>
                        <div className="skill-bar">
                           <span className="skill-percentage skill-java"></span>
                        </div>
                     </div>
                  </div>

                  <div className="skill-data">
                     <img src={psd} alt="Logo" className='skill-logo' />
                     <div className="skill-info">
                        <div className="skill-titles">
                           <h1 className="skill-name">Photoshop</h1>
                           <span className="skill-number">70%</span>
                        </div>
                        <div className="skill-bar">
                           <span className="skill-percentage skill-psd"></span>
                        </div>
                     </div>
                  </div>

                  <div className="skill-data">
                     <img src={ai} alt="Logo" className='skill-logo' />
                     <div className="skill-info">
                        <div className="skill-titles">
                           <h1 className="skill-name">Adobe Illustrator</h1>
                           <span className="skill-number">60%</span>
                        </div>
                        <div className="skill-bar">
                           <span className="skill-percentage skill-ai"></span>
                        </div>
                     </div>

                  </div>

                  <div className="skill-data">
                     <img src={ux} alt="Logo" className='skill-logo' />
                     <div className="skill-info">
                        <div className="skill-titles">
                           <h1 className="skill-name">UX / UI</h1>
                           <span className="skill-number">80%</span>
                        </div>
                        <div className="skill-bar">
                           <span className="skill-percentage skill-ux"></span>
                        </div>
                     </div>

                  </div>

                  <div className="skill-data">
                     <img src={sql_server} alt="Logo" className='skill-logo' />
                     <div className="skill-info">
                        <div className="skill-titles">
                           <h1 className="skill-name">SQL Server</h1>
                           <span className="skill-number">90%</span>
                        </div>
                        <div className="skill-bar">
                           <span className="skill-percentage skill-sqlserver"></span>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
         </section >
      </div >

   );
};

export default About;