import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

import { ReactComponent as LogoIcon } from '../assets/Logo_NC_Final-BLANCO.svg';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const logoLinkRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector('header');
      if (window.scrollY > 0) {
        header.classList.add('scrolled');
      } else {
        header.classList.remove('scrolled');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (logoLinkRef.current) {
      // Simula un clic en el enlace
      logoLinkRef.current.click();
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Animación suave
    });
  };


  return (
    <header className="header">
      <nav className='nav-container'>
        <Link to="/" className='logo-link' ref={logoLinkRef} onClick={scrollToTop}>
          <LogoIcon className='logo-icon' />
        </Link>

        <div className="menu-toggle" onClick={toggleMenu}>
          <div className="menu-icon"></div>
          <div className="menu-icon"></div>
          <div className="menu-icon"></div>
        </div>

        <ul className={`nav-list ${isMenuOpen ? 'open' : ''}`}>
          <li><a href="#cabecera">Home</a></li>
          <li><a href="#sobre_mi">Sobre mi</a></li>
          <li><a href="#proyectos">Proyectos</a></li>
          <li><a href="#contacto">Contacto</a></li>
        </ul>

        {isMenuOpen && (
          <div className="menu-overlay" onClick={toggleMenu}>
            <div className="close-btn" onClick={(e) => { e.stopPropagation(); toggleMenu(); }}>
              <span className="close-icon">&times;</span>
            </div>
            <ul className={`nav-list ${isMenuOpen ? 'open' : ''}`}>
              <li><a href="#cabecera">Home</a></li>
              <li><a href="#sobre_mi">Sobre mi</a></li>
              <li><a href="#proyectos">Proyectos</a></li>
              <li><a href="#contacto">Contacto</a></li>
            </ul>
          </div>
        )}
      </nav>
    </header>
  );
}

export default Header;