import React, { useEffect, useState, useRef } from 'react';
import './styles/Pages.css';

const Professional = () => {
    
    const [currentText, setCurrentText] = useState('');
    const [textIndex, setTextIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const bgContentRef = useRef(null);

    useEffect(() => {
        const texts = [
            "Full Stack Web Developer",
            "Database Architect",
            "Project Management",
            "Creative Problem Solver"
        ];

        let typingSpeed = 100;
        let erasingSpeed = 50;

        const handleTyping = () => {
            if (!isDeleting && currentText.length < texts[textIndex].length) {
                setCurrentText(texts[textIndex].slice(0, currentText.length + 1));
            } else if (isDeleting && currentText.length > 0) {
                setCurrentText(currentText.slice(0, currentText.length - 1));
            } else if (isDeleting && currentText.length === 0) {
                setIsDeleting(false);
                setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
            } else if (currentText.length === texts[textIndex].length) {
                setTimeout(() => setIsDeleting(true), 2000); // Pausa antes de empezar a borrar
            }
        };

        const timeoutId = setTimeout(handleTyping, isDeleting ? erasingSpeed : typingSpeed);

        return () => clearTimeout(timeoutId);
    }, [currentText, textIndex, isDeleting]);

    /*useEffect(() => {
        const handleMouseMove = (e) => {
            if (bgContentRef.current) {
                const amountMovedX = (e.pageX * -1 / 6);
                const amountMovedY = (e.pageY * -1 / 6);
                bgContentRef.current.style.backgroundPosition = `${amountMovedX}px ${amountMovedY}px`;
            }
        };

        const bgContent = bgContentRef.current;
        if (bgContent) {
            bgContent.addEventListener('mousemove', handleMouseMove);
        }

        return () => {
            if (bgContent) {
                bgContent.removeEventListener('mousemove', handleMouseMove);
            }
        };
    }, []);
    */
    return (
        <div className='professional'>
            <p>&nbsp;{currentText}</p>
            <div className="cover2">
                <div id="bg-content" ref={bgContentRef}>
                    <section className="inner">
                        {/* Your inner content here */}
                    </section>
                </div>
            </div>
        </div>
    );
};

export default Professional;
